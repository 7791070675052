import { Button } from "@mantine/core";
import { forwardRef, ForwardRefRenderFunction, MouseEventHandler } from "react";
import cx from "classnames";
import { AppLoader } from "src/components/AppLoader/AppLoader";

export interface AppButtonProps {
  theme: "primary" | "secondary" | "notification" | "tag";
  type?: "submit" | "reset" | "button" | undefined;
  disabled?: boolean | undefined;
  children: string;
  leftSection?: any;
  rightSection?: any;
  className?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  isLoading?: boolean;
  ariaLabel?: string;
}

const AppButton: ForwardRefRenderFunction<HTMLButtonElement, AppButtonProps> = (
  {
    theme,
    children,
    leftSection,
    rightSection,
    className,
    onClick,
    type,
    disabled,
    isLoading,
    ariaLabel,
  },
  ref,
) => {
  switch (theme) {
    case "secondary":
      return (
        <Button
          ref={ref}
          aria-label={ariaLabel}
          variant="outline"
          radius={"xl"}
          color="secondary"
          leftSection={!!leftSection ? leftSection : null}
          rightSection={!!rightSection ? rightSection : null}
          className={cx(
            "border-2 border-borderColor-light border-opacity-30 h-8 px-3 xl:px-4 xl:h-9 text-xs xl:text-sm",
            !!className ? className : "",
          )}
          onClick={onClick}
          type={type}
          disabled={disabled}
        >
          {children}
        </Button>
      );
    case "primary":
      if (!isLoading) {
        return (
          <Button
            ref={ref}
            aria-label={ariaLabel}
            color="primary"
            leftSection={!!leftSection ? leftSection : null}
            rightSection={!!rightSection ? rightSection : null}
            variant="filled"
            radius={"xl"}
            className={cx(
              `bg-button-primary ${!disabled ? "hover:bg-opacity-90 hover:bg-button-primary" : ""} h-8 px-3 xl:px-4 xl:h-9 text-xs xl:text-sm`,
              className,
            )}
            onClick={onClick}
            type={type}
            disabled={disabled}
          >
            {children}
          </Button>
        );
      }
      return <AppLoader variant="oval" />;
    case "tag":
      return (
        <Button
          ref={ref}
          aria-label={ariaLabel}
          color="tag"
          leftSection={!!leftSection ? leftSection : null}
          rightSection={!!rightSection ? rightSection : null}
          radius={"xl"}
          className={cx(
            `bg-button-tag ${!disabled ? "hover:bg-opacity-90 hover:bg-button-tag" : ""} h-8 px-3 xl:px-4 xl:h-9 text-xs xl:text-sm`,
            className,
          )}
          onClick={onClick}
          type={type}
          disabled={disabled}
        >
          {children}
        </Button>
      );
    case "notification":
      return (
        <Button
          ref={ref}
          aria-label={ariaLabel}
          color="notification"
          leftSection={!!leftSection ? leftSection : null}
          rightSection={!!rightSection ? rightSection : null}
          variant="filled"
          radius={"xl"}
          className={cx(
            `bg-button-notification ${!disabled ? "hover:bg-opacity-90 hover:bg-button-notification" : ""} h-8 px-3 xl:px-4 xl:h-9 text-xs xl:text-sm`,
            className,
          )}
          onClick={onClick}
          type={type}
          disabled={disabled}
        >
          {children}
        </Button>
      );
    default:
      return <Button ref={ref}>{children}</Button>;
  }
};

export default forwardRef(AppButton);
